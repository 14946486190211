<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <base-v-component
      heading="Extra"
    />
    <template v-if="error">
      <v-banner
        single-line
        elevation="10"
      >
        <v-icon
          slot="icon"
          color="warning"
          size="36"
        >
          mdi-wifi-strength-alert-outline
        </v-icon>
        <div>
          There was a problem communicating to the server!
          <div class="code">
            <strong>
              {{ error }}
            </strong>
          </div>
        </div>
      </v-banner>
    </template>
    <v-row>
      <v-col>
        <v-checkbox
          v-model="paused"
          color="secondary"
          :label="`Paused: ${paused.toString()}`"
          @change="set_status"
        >
          <template v-slot:label>
            Paused
          </template>
        </v-checkbox>
      </v-col>
    </v-row>
    <v-dialog
      v-model="loading"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Loading
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
  import { mapGetters } from 'vuex'
  import { formatterMixin } from '@/mixins/formatterMixin.js'
  import { countColorsMixin } from '@/mixins/countColorsMixin.js'
  import ExtraService from '@/services/ExtraService.js'

  export default {
    name: 'Settings',
    mixins: [formatterMixin, countColorsMixin],
    data () {
      return {
        loading: false,
        error: '',
        paused: false,
      }
    },
    computed: {
      ...mapGetters(['accountId', 'accountName']),
    },
    created () {
      this.get_status()
    },
    methods: {
      set_status: function () {
        var data = {
          remotePaused: this.paused.toString(),
        }
        ExtraService.setStatus(data)
          .then(response => {
          })
      },
      get_status: function () {
        ExtraService.getStatus()
          .then(response => {
            if (response.data.paused === 'true') { this.paused = true }
            if (response.data.paused === 'false') { this.paused = false }
          })
      },
    },
  }
</script>
