import axios from 'axios'

export default {
  getStatus () {
    return axios.get(
      '/extra/get_status?h=42',
    )
  },
  setStatus (data) {
    return axios.post(
      '/extra/set_status?h=42&remote_paused=' + data.remotePaused,
    )
  },
}
